import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import * as styles from './index.module.scss'
import NextIcon from "../../images/icons/next.inline.svg"

export const query = graphql`
  query {
    allMarketplace {
      edges {
        marketplace: node {
          name
          title
          sellerPage
          text
          styleName
          logoImage {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`

const ContactsPage = ({ data }) => {
  const marketplaces = data.allMarketplace.edges.map(({ marketplace }) => marketplace)

  return (
    <Layout
      pageTitle="Контакты"
      className={styles.content}
    >
      <h2>Контакты</h2>
      <section className={styles.contacts}>
        <p><a href="tel:88005556785">8 800 555 67 85</a> С 8:00 до 17:00</p>
        <p>E-mail <a href="mailto:info@sjersy.ru">info@sjersy.ru</a></p>
        <p>Россия, 153005, Ивановская обл., г. Иваново, ул. Спартака, д. 13а</p>
      </section>

      <section className={styles.marketplaces}>
        {marketplaces.map(marketplace => {
          return (
            <article
              key={`marketplace:${marketplace.name}`}
              className={`${styles.marketplaceCard} ${styles[marketplace.styleName]}`}
            >
              <GatsbyImage
                image={marketplace.logoImage.gatsbyImageData}
                alt={marketplace.title}
              />
              <a className={styles.link} href={marketplace.sellerPage}>
                <span>{marketplace.text}</span>
                <NextIcon className={styles.nextIcon} />
              </a>
            </article>
          )
        })}

      </section>
    </Layout>
  )
}

export default ContactsPage