// extracted by mini-css-extract-plugin
export var contacts = "index-module--contacts--pXPKd";
export var content = "index-module--content--Cv8UV";
export var detmir = "index-module--detmir--yg5dT";
export var kazanExpress = "index-module--kazan-express--6IZ3T";
export var link = "index-module--link--thBxj";
export var marketplaceCard = "index-module--marketplace-card--+3jvS";
export var marketplaces = "index-module--marketplaces--cJJwg";
export var nextIcon = "index-module--next-icon--8A4vi";
export var ozon = "index-module--ozon--RRDJC";
export var vk = "index-module--vk--Vb6j4";
export var wildberries = "index-module--wildberries--eNDgQ";
export var yandexMarket = "index-module--yandex-market--vJ0V3";